var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AError", { attrs: { api: this.api } }),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between" },
        [
          _c(
            "v-btn",
            {
              staticClass: "mt-3",
              attrs: { color: "primary" },
              on: { click: _vm.closeDetail },
            },
            [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
            1
          ),
          _c(
            "v-menu",
            {
              staticStyle: { padding: "0px" },
              attrs: { "offset-y": "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function (ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              attrs: {
                                "min-width": "150px",
                                "min-height": "50px",
                                color: "primary",
                              },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _vm._v(" Actions "),
                          _vm.actionDrop
                            ? _c("v-icon", [_vm._v(" mdi-arrow-up ")])
                            : _c("v-icon", [_vm._v(" mdi-arrow-down ")]),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.actionDrop,
                callback: function ($$v) {
                  _vm.actionDrop = $$v
                },
                expression: "actionDrop",
              },
            },
            [
              _c(
                "v-list",
                _vm._l(_vm.Actions, function (item, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      item.rolesAllowed.some(function (role) {
                        return _vm.$store.getters.getRole.includes(role)
                      })
                        ? _c(
                            "v-list-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.actions(item.action)
                                },
                              },
                            },
                            [
                              _c("v-icon", [_vm._v(" mdi-newspaper ")]),
                              _vm._v(_vm._s(item.name) + " "),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c("v-toolbar", { attrs: { color: "secondary" } }, [
            _c(
              "div",
              { staticClass: "white--text text-h5" },
              [
                _c("v-icon", { attrs: { color: "white" } }, [
                  _vm._v(" mdi-domain "),
                ]),
                _vm._v(" Company "),
              ],
              1
            ),
          ]),
          this.company
            ? _c(
                "v-card",
                { staticClass: "pa-5" },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pr-3", attrs: { cols: "12", sm: "6" } },
                        [
                          _c("div", { staticClass: "mx-2" }, [
                            _c(
                              "div",
                              { staticClass: "d-flex justify-space-between" },
                              [
                                _c("p", { staticClass: "text-h7" }, [
                                  _vm._v(" Name : "),
                                ]),
                                _c("p", { staticClass: "text-h7" }, [
                                  _vm._v(" " + _vm._s(_vm.company.name) + " "),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-space-between" },
                              [
                                _c("p", { staticClass: "text-h7" }, [
                                  _vm._v(" SSM : "),
                                ]),
                                _c("p", { staticClass: "text-h7" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.company.ssm_no
                                          ? _vm.company.ssm_no
                                          : "-"
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-space-between" },
                              [
                                _c("p", { staticClass: "text-h7" }, [
                                  _vm._v(" Address : "),
                                ]),
                                _c("p", { staticClass: "text-h7" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.company.address
                                          ? _vm.company.address
                                          : "-"
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-space-between" },
                              [
                                _c("p", { staticClass: "text-h7" }, [
                                  _vm._v(" Industry : "),
                                ]),
                                _c("p", { staticClass: "text-h7" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.company.industry
                                          ? _vm.company.industry
                                          : "-"
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-space-between" },
                              [
                                _c("p", { staticClass: "text-h7" }, [
                                  _vm._v(" Status : "),
                                ]),
                                _c(
                                  "p",
                                  { staticClass: "text-h7 font-weight-bold" },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.company.status) + " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-space-between" },
                              [
                                _c("div", { staticClass: "text-h5 mb-3" }, [
                                  _vm._v(" Payment method "),
                                ]),
                              ]
                            ),
                            _c("div", { staticClass: "d-flex justify-start" }, [
                              _c(
                                "p",
                                { staticClass: "text-h7 font-weight-bold" },
                                [_vm._v(" Credit card ")]
                              ),
                            ]),
                            _c("div", [
                              _c(
                                "div",
                                { staticClass: "d-flex justify-space-between" },
                                [
                                  _c("p", { staticClass: "text-h7" }, [
                                    _vm._v(" Brand : "),
                                  ]),
                                  _c("p", { staticClass: "text-h7" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.company.card_brand
                                            ? _vm.company.card_brand
                                            : "-"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex justify-space-between" },
                                [
                                  _c("p", { staticClass: "text-h7" }, [
                                    _vm._v(" Last 4 digits : "),
                                  ]),
                                  _c("p", { staticClass: "text-h7" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.company.card_last_four
                                            ? _vm.company.card_last_four
                                            : "-"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex justify-space-between" },
                                [
                                  _c("p", { staticClass: "text-h7" }, [
                                    _vm._v(" Expired at : "),
                                  ]),
                                  _c("p", { staticClass: "text-h7" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.company.expired
                                            ? _vm.company.expired
                                            : "-"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pl-3", attrs: { cols: "12", sm: "6" } },
                        [
                          _c("div", { staticClass: "text-h5 mb-3" }, [
                            _vm._v(" Last subscription "),
                          ]),
                          this.subscription
                            ? _c("div", { staticClass: "mx-2" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex justify-space-between",
                                  },
                                  [
                                    _c("p", { staticClass: "text-h7" }, [
                                      _vm._v(" Name : "),
                                    ]),
                                    _c("p", { staticClass: "text-h7" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.subscription.name) +
                                          " " +
                                          _vm._s(_vm.subscription.name2) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex justify-space-between",
                                  },
                                  [
                                    _c("p", { staticClass: "text-h7" }, [
                                      _vm._v(" Price : "),
                                    ]),
                                    _vm.subscription.coupon
                                      ? _c(
                                          "p",
                                          {
                                            staticClass:
                                              "text-h7 text-decoration-line-through",
                                          },
                                          [
                                            _vm._v(
                                              " RM " +
                                                _vm._s(_vm.subscription.price) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c("p", { staticClass: "text-h7" }, [
                                          _vm._v(
                                            " RM " +
                                              _vm._s(_vm.subscription.price) +
                                              " "
                                          ),
                                        ]),
                                  ]
                                ),
                                _vm.subscription.coupon
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-space-between",
                                      },
                                      [
                                        _c("p", { staticClass: "text-h7" }, [
                                          _vm._v(" Discount : "),
                                        ]),
                                        _c("p", { staticClass: "text-h7" }, [
                                          _vm._v(
                                            " RM " +
                                              _vm._s(
                                                _vm.subscription.price -
                                                  _vm.subscription.amount_paid
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.subscription.coupon
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-space-between",
                                      },
                                      [
                                        _c("p", { staticClass: "text-h7" }, [
                                          _vm._v(" Total price : "),
                                        ]),
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "text-h7 font-weight-bold",
                                          },
                                          [
                                            _vm._v(
                                              " RM " +
                                                _vm._s(
                                                  _vm.subscription.amount_paid
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex justify-space-between",
                                  },
                                  [
                                    _c("p", { staticClass: "text-h7" }, [
                                      _vm._v(" Coupon : "),
                                    ]),
                                    _c("p", { staticClass: "text-h7" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.subscription.coupon
                                              ? _vm.subscription.coupon
                                              : "Not applicable"
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex justify-space-between",
                                  },
                                  [
                                    _c("p", { staticClass: "text-h7" }, [
                                      _vm._v(" Subscription date : "),
                                    ]),
                                    _c("p", { staticClass: "text-h7" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.subscription.created_at.split(
                                              "T"
                                            )[0]
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex justify-space-between",
                                  },
                                  [
                                    _c("p", { staticClass: "text-h7" }, [
                                      _vm._v(" Next renewal date : "),
                                    ]),
                                    _c("p", { staticClass: "text-h7" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.subscription.renewal_at.split(
                                              " "
                                            )[0]
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-end" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "text-h10",
                                        attrs: { color: "primary" },
                                        on: { click: _vm.readAllSubscription },
                                      },
                                      [_vm._v(" Show all ")]
                                    ),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "mt-3" }, [
                                  _c("hr"),
                                  _c("div", { staticClass: "text-h5 my-2" }, [
                                    _vm._v(" Latest renewal "),
                                  ]),
                                  _vm.latestRenewalForm
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-space-between",
                                            },
                                            [
                                              _c("p", [
                                                _vm._v("Report date :"),
                                              ]),
                                              _c("p", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.latestRenewalForm.created_at.split(
                                                        "T"
                                                      )[0]
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex justify-space-between mb-2",
                                              },
                                              [
                                                _c("div", [
                                                  _vm._v(
                                                    " Previous renewal status "
                                                  ),
                                                ]),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-row justify-end",
                                                  },
                                                  [
                                                    _vm._l(
                                                      _vm.renewalStatus,
                                                      function (item, index) {
                                                        return _c(
                                                          "div",
                                                          { key: index },
                                                          [
                                                            index < 5 &&
                                                            index >= 1
                                                              ? _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "v-tooltip",
                                                                      {
                                                                        attrs: {
                                                                          bottom:
                                                                            "",
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "activator",
                                                                                fn: function (
                                                                                  ref
                                                                                ) {
                                                                                  var on =
                                                                                    ref.on
                                                                                  var attrs =
                                                                                    ref.attrs
                                                                                  return [
                                                                                    item.status ==
                                                                                    "Confirm"
                                                                                      ? _c(
                                                                                          "v-icon",
                                                                                          _vm._g(
                                                                                            _vm._b(
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    color:
                                                                                                      "green",
                                                                                                  },
                                                                                              },
                                                                                              "v-icon",
                                                                                              attrs,
                                                                                              false
                                                                                            ),
                                                                                            on
                                                                                          ),
                                                                                          [
                                                                                            _vm._v(
                                                                                              " mdi-checkbox-marked "
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    item.status ==
                                                                                    "Pending"
                                                                                      ? _c(
                                                                                          "v-icon",
                                                                                          _vm._g(
                                                                                            _vm._b(
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    color:
                                                                                                      "yellow",
                                                                                                  },
                                                                                              },
                                                                                              "v-icon",
                                                                                              attrs,
                                                                                              false
                                                                                            ),
                                                                                            on
                                                                                          ),
                                                                                          [
                                                                                            _vm._v(
                                                                                              " mdi-checkbox-marked "
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    item.status ==
                                                                                    "Unreachable"
                                                                                      ? _c(
                                                                                          "v-icon",
                                                                                          _vm._g(
                                                                                            _vm._b(
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    color:
                                                                                                      "grey",
                                                                                                  },
                                                                                              },
                                                                                              "v-icon",
                                                                                              attrs,
                                                                                              false
                                                                                            ),
                                                                                            on
                                                                                          ),
                                                                                          [
                                                                                            _vm._v(
                                                                                              " mdi-checkbox-marked "
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    item.status ==
                                                                                    "Rejected"
                                                                                      ? _c(
                                                                                          "v-icon",
                                                                                          _vm._g(
                                                                                            _vm._b(
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    color:
                                                                                                      "red",
                                                                                                  },
                                                                                              },
                                                                                              "v-icon",
                                                                                              attrs,
                                                                                              false
                                                                                            ),
                                                                                            on
                                                                                          ),
                                                                                          [
                                                                                            _vm._v(
                                                                                              " mdi-checkbox-marked "
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                  ]
                                                                                },
                                                                              },
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      item.created_at
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      item.status
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "hr"
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      item.remark.substring(
                                                                                        0,
                                                                                        25
                                                                                      )
                                                                                    ) +
                                                                                    "... "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            index == 0
                                                              ? _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "v-tooltip",
                                                                      {
                                                                        attrs: {
                                                                          bottom:
                                                                            "",
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "activator",
                                                                                fn: function (
                                                                                  ref
                                                                                ) {
                                                                                  var on =
                                                                                    ref.on
                                                                                  var attrs =
                                                                                    ref.attrs
                                                                                  return [
                                                                                    item.status ==
                                                                                    "Rejected"
                                                                                      ? _c(
                                                                                          "v-chip",
                                                                                          _vm._g(
                                                                                            _vm._b(
                                                                                              {
                                                                                                staticClass:
                                                                                                  "white--text",
                                                                                                attrs:
                                                                                                  {
                                                                                                    color:
                                                                                                      "red",
                                                                                                  },
                                                                                              },
                                                                                              "v-chip",
                                                                                              attrs,
                                                                                              false
                                                                                            ),
                                                                                            on
                                                                                          ),
                                                                                          [
                                                                                            _vm._v(
                                                                                              " " +
                                                                                                _vm._s(
                                                                                                  item.status
                                                                                                ) +
                                                                                                " "
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    item.status ==
                                                                                    "Confirm"
                                                                                      ? _c(
                                                                                          "v-chip",
                                                                                          _vm._g(
                                                                                            _vm._b(
                                                                                              {
                                                                                                staticClass:
                                                                                                  "white--text",
                                                                                                attrs:
                                                                                                  {
                                                                                                    color:
                                                                                                      "green",
                                                                                                  },
                                                                                              },
                                                                                              "v-chip",
                                                                                              attrs,
                                                                                              false
                                                                                            ),
                                                                                            on
                                                                                          ),
                                                                                          [
                                                                                            _vm._v(
                                                                                              " " +
                                                                                                _vm._s(
                                                                                                  item.status
                                                                                                ) +
                                                                                                " "
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    item.status ==
                                                                                    "Pending"
                                                                                      ? _c(
                                                                                          "v-chip",
                                                                                          _vm._g(
                                                                                            _vm._b(
                                                                                              {
                                                                                                staticClass:
                                                                                                  "white--text",
                                                                                                attrs:
                                                                                                  {
                                                                                                    color:
                                                                                                      "yellow",
                                                                                                  },
                                                                                              },
                                                                                              "v-chip",
                                                                                              attrs,
                                                                                              false
                                                                                            ),
                                                                                            on
                                                                                          ),
                                                                                          [
                                                                                            _vm._v(
                                                                                              " " +
                                                                                                _vm._s(
                                                                                                  item.status
                                                                                                ) +
                                                                                                " "
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    item.status ==
                                                                                    "Unreachable"
                                                                                      ? _c(
                                                                                          "v-chip",
                                                                                          _vm._g(
                                                                                            _vm._b(
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    color:
                                                                                                      "grey",
                                                                                                  },
                                                                                              },
                                                                                              "v-chip",
                                                                                              attrs,
                                                                                              false
                                                                                            ),
                                                                                            on
                                                                                          ),
                                                                                          [
                                                                                            _vm._v(
                                                                                              " " +
                                                                                                _vm._s(
                                                                                                  item.status
                                                                                                ) +
                                                                                                " "
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                  ]
                                                                                },
                                                                              },
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      item.created_at
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "hr"
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      item.remark.substring(
                                                                                        0,
                                                                                        25
                                                                                      )
                                                                                    ) +
                                                                                    "... "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color: "primary",
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.readAllRenewalUpdate,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " mdi-dots-horizontal-circle "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex justify-space-between mb-2",
                                              },
                                              [
                                                _c("div", [
                                                  _vm._v(
                                                    " Total renewal update (2023) "
                                                  ),
                                                ]),
                                                _c("div", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.renewalStatus.length
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]),
                                          _vm.latestRenewalForm
                                            .renewal_status == "Confirm"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex justify-space-between",
                                                },
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      " Client promised payment date : "
                                                    ),
                                                  ]),
                                                  _c("p", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.latestRenewalForm.confirm_at.split(
                                                            "T"
                                                          )[0]
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c("p", [_vm._v(" Remark : ")]),
                                          _vm.latestRenewalForm.remark != null
                                            ? _c("mavon-editor", {
                                                staticStyle: {
                                                  "z-index": "0",
                                                  "min-height": "100px",
                                                  height: "auto",
                                                  width: "100%",
                                                },
                                                attrs: {
                                                  height: "auto",
                                                  width: "auto",
                                                  defaultOpen: "preview",
                                                  toolbarsFlag: false,
                                                  subfield: false,
                                                  language: "en",
                                                  editable: false,
                                                },
                                                model: {
                                                  value:
                                                    _vm.latestRenewalForm
                                                      .remark,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.latestRenewalForm,
                                                      "remark",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "latestRenewalForm.remark",
                                                },
                                              })
                                            : _c("mavon-editor", {
                                                staticStyle: {
                                                  "z-index": "0",
                                                  "min-height": "100px",
                                                  height: "auto",
                                                  width: "100%",
                                                },
                                                attrs: {
                                                  height: "auto",
                                                  width: "auto",
                                                  defaultOpen: "preview",
                                                  toolbarsFlag: false,
                                                  subfield: false,
                                                  language: "en",
                                                  editable: false,
                                                },
                                                model: {
                                                  value: _vm.isRemarkNull,
                                                  callback: function ($$v) {
                                                    _vm.isRemarkNull = $$v
                                                  },
                                                  expression: "isRemarkNull",
                                                },
                                              }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-end mt-2",
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: { color: "primary" },
                                                  on: {
                                                    click:
                                                      _vm.readAllRenewalUpdate,
                                                  },
                                                },
                                                [_vm._v(" Show all ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _c("div", [
                                        _c("span", [
                                          _vm._v("No renewal status update"),
                                        ]),
                                      ]),
                                ]),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("hr", { staticClass: "mt-6" }),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "px-7",
                          attrs: { cols: "12", sm: "12" },
                        },
                        [
                          _c(
                            "v-tabs",
                            { attrs: { color: "secondary" } },
                            [
                              _c("v-tab", [_vm._v(" User ")]),
                              _c("v-tab", [_vm._v(" PIC ")]),
                              _c(
                                "v-tab-item",
                                [
                                  _c(
                                    "v-card",
                                    {
                                      attrs: {
                                        height: "auto",
                                        width: "auto",
                                        "elevation-6": "",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        _vm._l(
                                          _vm.client,
                                          function (item, index) {
                                            return _c(
                                              "v-col",
                                              {
                                                key: index,
                                                attrs: { cols: "12", sm: "6" },
                                              },
                                              [
                                                _c(
                                                  "v-card",
                                                  { staticClass: "pa-2 ma-2" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "mx-2" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex justify-space-between",
                                                          },
                                                          [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "text-h7",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Name : "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "text-h7",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.user_name
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex justify-space-between",
                                                          },
                                                          [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "text-h7",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Position : "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "text-h7",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.position
                                                                        ? item.position
                                                                        : "-"
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex justify-space-between",
                                                          },
                                                          [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "text-h7",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Phone no : "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "text-h7",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.mobile
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex justify-space-between",
                                                          },
                                                          [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "text-h7",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Email : "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "text-h7",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.email
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex justify-space-between",
                                                          },
                                                          [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "text-h7",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Gender : "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "text-h7",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.gender
                                                                        ? item.gender
                                                                        : "-"
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-tab-item",
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _vm.staff != null
                                        ? _c(
                                            "v-row",
                                            _vm._l(
                                              _vm.staff,
                                              function (item, index) {
                                                return _c(
                                                  "v-col",
                                                  {
                                                    key: index,
                                                    attrs: {
                                                      cols: "12",
                                                      sm: "4",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-card",
                                                      { staticClass: "pa-2" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex justify-space-between",
                                                          },
                                                          [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "text-h7",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Name : "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "text-h7",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.name
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex justify-space-between",
                                                          },
                                                          [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "text-h7",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Phone no : "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "text-h7",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.mobile
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex justify-space-between",
                                                          },
                                                          [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "text-h7",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Email : "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "text-h7",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.email
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex justify-space-between",
                                                          },
                                                          [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "text-h7",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Role : "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "text-h7",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.role
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex justify-space-between",
                                                          },
                                                          [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "text-h7",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Gender : "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "text-h7",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.gender
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        : _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { staticClass: "mt-2" },
                                                [
                                                  _c("AWarning", {
                                                    attrs: {
                                                      message:
                                                        "No Person-in-charge assigned",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("div", { staticClass: "d-flex justify-end my-5" }),
          _c("v-toolbar", { attrs: { color: "secondary white--text" } }, [
            _vm._v(" Log "),
          ]),
          _c(
            "div",
            [
              _vm.logs != null
                ? _c(
                    "v-card",
                    _vm._l(_vm.logs, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "pa-3" },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-space-between pa-3 ",
                                },
                                [
                                  _c("div", { staticClass: "d-flex" }, [
                                    _vm._v(" " + _vm._s(item.title) + " "),
                                  ]),
                                  _c("div", { staticClass: "d-flex" }),
                                ]
                              ),
                              _c(
                                "v-card-text",
                                { staticClass: "d-flex justify-start" },
                                [_vm._v(" " + _vm._s(item.description) + " ")]
                              ),
                            ],
                            1
                          ),
                          _c("div", {
                            staticClass: "d-flex justify-center py-4",
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _c("v-card", [
                    _c("div", { staticClass: "pa-3" }, [
                      _vm._v(" No logs recorded "),
                    ]),
                  ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }